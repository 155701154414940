<script>
  import appConfig from "@/app.config";
  import PageHeader from "@/components/page-header";

  export default {
    page: {
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    },
    components: {

      PageHeader,
    },
    data() {
      return {
        users: [],
        Users: {},
        page: 1,
        limit: 20,
        tot_pages: 0,
        searchUsers: null,
        SearchMode: false,
        emps: [],
        new_roles: [],
        newRoles: [{}],
        addRoles: [{}],
        branches: [],
        user_info: {},
        new_pass: "",
        users_switch: "",
        user_status: 0,
        data: {},
        roles: [],

        // appprices:[]
      };
    },
    methods: {
      editUser() {
        console.log(this.user_info);
        this.http
          .put("users/", this.user_info.id, {
            new_roles: this.newRoles,
            name: this.user_info.name,
            email: this.user_info.email,
            branch: this.user_info.emp.branch.name,
          })
          .then(() => {
            this.get(this.page);
            console.log(this.newRoles);
          });
      },
      addUsers() {
        this.data.new_roles = this.addRoles;
        this.http.post("users/register", this.data).then(() => {
          this.data = {};
          this.addRoles = [{}];
          this.get(this.page);
        });
      },
      search() {
        this.SearchMode = true;
        console.log(this.searchUsers);
        this.tot_pages = 0;
        this.http
          .post("users/search", {
            limit: this.limit,
            page: this.page,
            search: this.searchUsers,
            col: "name",
          })
          .then((res) => {
            this.users = res.data;
          });
      },
      cancelSearchMode() {
        this.searchUsers = "";
        this.SearchMode = false;
        this.get(this.page);
      },
      getUsers() {
        this.http.get("users").then((res) => {
          this.users = res.data;
        });
      },
      deleteUser(user) {
        var data = {
          title: "popups.deleteuser",
          msg: "popups.deletemsg",
          buttons: [
            {
              text: "Delete",
              closer: true,
              color: "danger",
              handler: () => {
                console.log(user);
                this.http.delete("users", user.id).then(() => {
                  this.get(this.page);
                });
              },
            },
            {
              text: "Cancel",
              closer: true,
              color: "light",
              handler: () => {
                console.log("Like Clicked");
              },
            },
          ],
        };
        this.popup.confirm(data);
      },
      userInfo(user) {
        this.user_info = user;
        console.log(this.user_info);
      },
      editPass() {
        console.log(this.user_info.id);
        this.http.post("users/update-password", {
          user_id: this.user_info.id,
          pass: this.new_pass,
        });
      },

      get(page) {
        console.log(this.userstable);
        this.http
          .post("users/pagination", {
            limit: this.limit,
            page: page,
          })
          .then((res) => {
            this.page = page;
            this.tot_pages = Math.ceil(res.tot / this.limit);
            this.users = res.data;
          });
      },
      getEmp() {
        this.http.get("emps").then((res) => {
          this.emps = res.data;
        });
      },
      getRole() {
        this.http.get("roles").then((res) => {
          this.roles = res.data;
        });
      },
      getBransh() {
        this.http.get("branches").then((res) => {
          this.branches = res.data;
        });
      },
      getSwitch(user, status) {
        console.log(status);
        this.http
          .post("users/update-status", {
            status: status,
            user_id: user.id,
          })
          .then(() => {
            this.get(this.page);
          });
      },
    },
    created() {
      // this.get(1);
      // this.getEmp();
      // this.getRole();
      // this.getBransh();
      // this.getUsers();
    },
  };
</script>

<template>

    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.hr.subItems.payroll')"
    />
    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box" style="width: 250px">
          <div class="position-relative">
            <input
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
              :placeholder="$t('users.searchplaceholder')"
              v-model="searchUsers"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
            <button
              @click="cancelSearchMode()"
              v-if="SearchMode"
              class="btn-close text-light"
              style="position: absolute; top: 12px; left: 250px"
            ></button>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button
          type="button"
          class="btn btn-light float-end mb-4"
          data-bs-toggle="modal"
          data-bs-target="#addModal"
        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
          <th scope="col">{{ $t("users.userstable.id") }}</th>
          <th scope="col">{{ $t("users.userstable.user") }}</th>
          <th scope="col">{{ $t("users.userstable.email") }}</th>
          <th scope="col">{{ $t("users.userstable.branch") }}</th>
          <th scope="col">{{ $t("users.userstable.roles") }}</th>
          <th scope="col">{{ $t("users.userstable.created") }}</th>
          <th scope="col">{{ $t("users.userstable.updated") }}</th>
          <th scope="col">{{ $t("users.userstable.operations") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center" v-for="(user, index) in users" :key="user">
          <td>{{ index + 1 }}</td>
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user?.emp?.branch?.name }}</td>
          <td>{{ user?.user_role?.role?.role }}</td>
          <td>{{ user.created.split("T")[0] }}</td>
          <td>{{ user.updated.split("T")[0] }}</td>
          <td>
            <button
              @click="getSwitch(user, 1)"
              v-if="user.status == 0"
              class="btn btn-primary btn-sm me-2"
            >
              {{ $t("popups.enable") }}
            </button>
            <button
              type="button"
              @click="getSwitch(user, 0)"
              v-if="user.status == 1"
              class="btn btn-secondary btn-sm me-2"
            >
              {{ $t("popups.disable") }}
            </button>
            <button
              type="button"
              class="btn btn-primary me-2 btn-sm"
              data-bs-target="#editModal"
              data-bs-toggle="modal"
              @click="userInfo(user)"
            >
              {{ $t("popups.edit") }}
            </button>
            <button
              type="button"
              class="btn btn-primary me-2 btn-sm"
              data-bs-target="#editPassModal"
              data-bs-toggle="modal"
              @click="userInfo(user)"
            >
              {{ $t("popups.resetpass") }}
            </button>
            <a
              class="btn btn-danger btn-sm me-2"
              :class="{}"
              href="javascript:void(0)"
              role="button"
              @click="deleteUser(user)"
              >{{ $t("popups.delete") }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!--Start Add Modal-->
    <div
      class="modal fade"
      id="addModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="addUsers()">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("popups.adduser") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body">
              <label for="user">{{ $t("popups.employee") }}</label>
              <select v-model="data.emp_id" class="form-select">
                <option v-for="emp in emps" :key="emp" :value="emp.id">
                  {{ emp.name }}
                </option>
              </select>

              <label for="usename">{{ $t("popups.username") }}</label>
              <input
                v-model="data.name"
                id="user"
                required
                class="form-control"
                type="text"
                :placeholder="$t('popups.username')"
              />

              <label for="user">{{ $t("popups.email") }}</label>
              <input
                v-model="data.email"
                id="user"
                required
                class="form-control"
                type="text"
                :placeholder="$t('popups.email')"
              />

              <label for="pass">{{ $t("popups.password") }}</label>
              <input
                v-model="data.pass"
                id="pass"
                required
                class="form-control"
                type="text"
                :placeholder="$t('popups.password')"
              />

              <label for="user_branch">{{ $t("popups.branch") }}</label>

              <select
                v-model="data.branch_id"
                required
                id="user_branch"
                class="form-select"
              >
                <option
                  v-for="branch in branches"
                  :key="branch"
                  :value="branch.id"
                >
                  {{ branch.name }}
                </option>
              </select>
              <div class="row d-flex align-items-center">
                <div class="col">
                  <label for="user_role">{{ $t("popups.role") }}</label>
                </div>
                <div class="col d-flex justify-content-end">
                  <button
                    type="button"
                    @click="addRoles.push({})"
                    class="btn float-end"
                  >
                    <span
                      class="bx bxs-plus-circle float-end fa-2x"
                      style="color: #2a3042 !important"
                    ></span>
                  </button>
                </div>
              </div>
              <div v-for="role in addRoles" :key="role">
                <select
                  v-model="role.role_id"
                  class="form-select mb-2"
                  id="user_role"
                >
                  <option v-for="r in roles" :key="r" :value="r.id">
                    {{ r.role }}
                  </option>
                </select>
              </div>
            </div>
            <div
              class="modal-footer"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <button type="submit" class="btn btn-primary">
                {{ $t("popups.add") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--End Add Modal-->

    <!-- Edit Modal -->
    <div
      class="modal fade"
      id="editModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="editUser()">
            <div class="modal-header" :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("popups.edituser") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body" :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
              <label for="user">{{ $t("popups.username") }}</label>
              <input
                id="user"
                required
                class="form-control"
                type="text"
                :placeholder="$t('popups.username')"
                v-model="data.name"
              />

              <label for="user">{{ $t("popups.email") }}</label>
              <input
                v-model="data.email"
                id="user"
                required
                class="form-control"
                type="text"
                :placeholder="$t('popups.email')"
              />

              <label for="user_branch">{{ $t("popups.branch") }}</label>

              <select
                v-model="user_info.branch"
                required
                id="user_branch"
                class="form-select"
              >
                <option
                  v-for="branch in branches"
                  :key="branch"
                  :value="branch.id"
                >
                  {{ branch.name }}
                </option>
              </select>
              <div class="row d-flex align-items-center">
                <div class="col">
                  <label for="user_role">{{ $t("popups.role") }}</label>
                </div>
                <div class="col d-flex justify-content-end">
                  <button
                    type="button"
                    @click="newRoles.push({})"
                    class="btn float-end"
                  >
                    <span
                      class="bx bxs-plus-circle float-end fa-2x"
                      style="color: #2a3042 !important"
                    ></span>
                  </button>
                </div>
              </div>
              <div v-for="role in newRoles" :key="role">
                <select
                  required
                  v-model="role.role_id"
                  class="form-select mb-2"
                  id="user_role"
                >
                  <option v-for="r in roles" :key="r" :value="r.id">
                    {{ r.role }}
                  </option>
                </select>
              </div>
            </div>
            <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
              <button type="submit" class="btn btn-primary">
                {{ $t("popups.edit") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--end Edit Modal-->
    <!--Edit Pass Modsl-->
    <div
      class="modal fade"
      id="editPassModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="editPass()">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("popups.resetpass") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body">
              <label for="pass" class="form-label">{{
                $t("popups.resetpass")
              }}</label>
              <input
                v-model="new_pass"
                required
                type="password"
                class="form-control"
                id="pass"
                :placeholder="$t('popups.resetpass')"
              />
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">
                {{ $t("popups.edit") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--Edit pass Modal-->
    <!--   Apps  Pagination     -->
    <ul
      v-if="tot_pages >= 20"
      class="pagination pagination-rounded justify-content-center mb-2"
    >
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
    <!--end-->

</template>
